import ProductsComponent from "../../Components/Products/Products";

const Products = () => {
  return (
    <>
      <ProductsComponent />
    </>
  );
};

export default Products;
