import AddCompany from "../../Components/Companies/Addcompany";

const Companies = () => {
  return (
    <>
      <AddCompany />
    </>
  );
};

export default Companies;
