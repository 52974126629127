import List from "../../Components/Customers/List";


const Customers = () => {
  return (
    <>
        <List/>
    </>
  );
}

export default Customers;
